<script setup lang="ts">
const { t } = useI18n()
import { debounce } from 'lodash'
import { apiStore } from '~/stores/api'
import { userStore } from '~/stores/user'
import type { ToastList } from '~/types/toast'
import { Status } from '~/utils/entity'

import { useKeyupHandler } from '~/composables/useKeyupHandler'
import { isForvia } from '~/helpers/growthbook/growthbook'
import { growthBookKey } from '~/modules/growthbook'
import { configStore } from '~/stores/configs'
import { entityTypesStore } from '~/stores/entity_types'
import { viewsStore } from '~/stores/views'
import { unwrapApiErrors } from '~/types/api'
import { Bom } from '~/types/view-elements'
import { unwrapRouteParam } from '~/utils/route'
import BomStatusFilter from './BomStatusFilter.vue'

const growthBookInjectable = inject(growthBookKey)

const api = apiStore().getApiClient
const router = useRouter()
const route = useRoute()

const state = reactive<{
  boms: Bom[]
  isLoading: boolean
  isLoadingMore: boolean
  currentPage: number
  perPage: number
  hasFetchingNext: boolean
  observer: IntersectionObserver | null
  supportedIntersectObserver: boolean
  isReachedAtLast: boolean
  hasAddBomModalOpen: boolean
  hasAddingBom: boolean
  search: string
  status: string
  toast?: ToastList
  selectedIds: Set<string>
  hasDeleteBomModalOpen: boolean
  isDeleting: boolean
  isImportModalOpen: boolean
  isForvia: boolean
  imagePath: string | null
  isFullscreenImageModalOpen: boolean
  selectAll: boolean
}>({
  boms: [],
  isLoading: false,
  isLoadingMore: false,
  currentPage: 1,
  perPage: 20,
  hasFetchingNext: false,
  observer: null as IntersectionObserver | null,
  supportedIntersectObserver: false,
  isReachedAtLast: false,
  hasAddBomModalOpen: false,
  hasAddingBom: false,
  search: '',
  status: unwrapRouteParam(route.query.status) || '',
  toast: inject<ToastList>('toast'),
  selectedIds: new Set(),
  hasDeleteBomModalOpen: false,
  isDeleting: false,
  isImportModalOpen: false,
  isForvia: false,
  imagePath: null as null | string,
  isFullscreenImageModalOpen: false,
  selectAll: false,
})

const canCreateBom = computed(() => {
  return userStore()?.user?.roles.includes('admin') || userStore()?.user?.roles.includes('project_manager')
})

// Observer & Un-observe
function registerObserver() {
  let options = {
    rootMargin: '0px',
    root: null,
  }

  state.observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.intersectionRatio > 0) {
        loadPaginatedData(state.currentPage)
      }
    })
  }, options)

  let target = document.querySelector('#issueObserver')
  if (target) {
    state.observer.observe(target)
  }
}

function unRegisterObserver() {
  let target = document.querySelector('#issueObserver')
  if (target && state.observer) {
    state.observer.unobserve(target)
    state.observer = null
  }
}

async function loadPaginatedData(page: number = 1) {
  if (state.hasFetchingNext || state.isReachedAtLast) return

  if (page === 1) {
    state.isLoading = true
  } else {
    state.isLoadingMore = true
  }

  state.hasFetchingNext = true

  try {
    const [response] = await Promise.all([
      await api.getBoms(page, state.perPage, {
        name: state.search,
        status: state.status,
      }),
      viewsStore().loadViews(),
      entityTypesStore().loadEntityTypes(),
    ])
    const d = response?.data.map(d => {
      Object.assign(d, { is_checked: state.selectAll })
      return d
    })

    state.boms.push(...d)

    if (response.meta.current_page == response.meta.last_page) state.isReachedAtLast = true

    if (state.boms.length <= 0) {
      state.selectedIds = new Set()
      state.selectAll = false
    }

    state.currentPage += 1
    state.hasFetchingNext = false
  } catch (error) {
    router.push('/')
    const errorMessage = unwrapApiErrors(error)
    state.toast?.error(t('global.error'), errorMessage)
  } finally {
    if (page === 1) {
      state.isLoading = false
    } else {
      state.isLoadingMore = false
    }
  }
}

function goToBomDetails(row: Bom) {
  if (!row.auth.can.view) return

  const view_id = unwrapRouteParam(route.query.view_id) || configStore().getClientDefaultView
  const view_type =
    (unwrapRouteParam(route.query.view_type) as 'tree' | 'graph' | 'table') ||
    configStore().getClientDefaultViewDisplayType
  viewsStore().setDisplayType(view_type)

  router.push({
    path: `/boms/${row.id}`,
    query: {
      ...route.query,
      view_id,
      view_type,
      bom_tab: 1,
    },
  })
}

function openAddBomModal() {
  state.hasAddBomModalOpen = true
}

function closeAddBomModal() {
  state.hasAddBomModalOpen = false
}

function createBom(data: Bom) {
  state.boms.unshift(data)
}

const searchTextChanged = debounce(async () => {
  state.currentPage = 1
  state.isReachedAtLast = false
  state.boms = []
  state.selectAll = false
  state.selectedIds = new Set()
  await loadPaginatedData(1)
}, 400)

async function filterStatusChanged(status: string) {
  state.status = status
  state.currentPage = 1
  state.isReachedAtLast = false
  state.boms = []
  state.selectAll = false
  state.selectedIds = new Set()
  await loadPaginatedData(1)
}

function openDeleteBomModal() {
  state.hasDeleteBomModalOpen = true
}

function closeDeleteBomModal() {
  state.hasDeleteBomModalOpen = false
}

async function deleteBoms() {
  try {
    state.isDeleting = true

    for (const bomId of state.selectedIds) {
      const idx = state.boms.findIndex(e => e.id === bomId)

      if (idx !== -1) {
        await api.removeBom(bomId)
        state.boms.splice(idx, 1)
      }
    }
    state.selectedIds = new Set()

    state.toast?.success(t('global.success'), t('boms.delete_success'))
    closeDeleteBomModal()
  } catch (error) {
    const errorMessage = unwrapApiErrors(error)
    state.toast?.error(t('global.error'), errorMessage)
  } finally {
    state.isDeleting = false
  }
}

function openImportModal() {
  state.isImportModalOpen = true
}

function closeImportModal() {
  state.isImportModalOpen = false
}

function openFullScreenImageModal(imagePath: string) {
  if (!imagePath) {
    return
  }

  state.imagePath = imagePath
  state.isFullscreenImageModalOpen = true
}

function closeFullScreenImageModal() {
  state.isFullscreenImageModalOpen = false
}

onBeforeMount(async () => {
  const growthBook = await growthBookInjectable?.init()
  state.isForvia = (await isForvia(growthBook)) || false
})

const handleEscapeKey = () => {
  closeAddBomModal()
  closeDeleteBomModal()
}

useKeyupHandler(handleEscapeKey)

onMounted(async () => {
  if (!window.IntersectionObserver) {
    state.supportedIntersectObserver = false
  } else {
    state.supportedIntersectObserver = true
  }

  await loadPaginatedData(1)
})

watch(
  () => state.boms.length,
  () => {
    if (!state.supportedIntersectObserver) return
    registerObserver()
  },
)

watch(
  () => state.isReachedAtLast,
  newVal => {
    if (newVal && state.supportedIntersectObserver) {
      unRegisterObserver()
    }
  },
)

const columns = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'image',
    label: 'Image',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'reference',
    label: 'Reference',
  },
  {
    key: 'updated_at',
    label: 'Last Updated',
    type: 'date',
  },
]

function onRowselected(row: Bom | boolean) {
  if (typeof row === 'boolean') {
    if (row) {
      state.selectedIds = new Set(...state.boms.map(bom => bom.id))
    } else {
      state.selectedIds = new Set()
    }
  } else {
    if (state.selectedIds.has(row.id)) {
      state.selectedIds.delete(row.id)
    } else {
      state.selectedIds.add(row.id)
    }
  }
}
</script>

<template>
  <div>
    <div class="w-full flex items-center justify-between">
      <div class="flex items-center relative">
        <BomStatusFilter :options="Status" :value="state.status" @update:model-value="filterStatusChanged" />
        <div class="pl-2">
          <OSearchBar
            v-model="state.search"
            :placeholder="`${$t('global.search')}`"
            class="!w-96"
            @update:modelValue="searchTextChanged"
          />
        </div>
      </div>
      <div class="flex">
        <button
          class="btn-danger flex items-center gap-2 first:mr-2"
          v-if="state.selectedIds.size > 0"
          @click="openDeleteBomModal"
        >
          <div i="carbon-trash-can" />
          {{ $t('global.delete') }}
        </button>

        <div class="flex" v-if="canCreateBom">
          <button class="btn-secondary gap-1.5 h-full h-fit mr-2" @click="openImportModal">
            <div i="carbon-upload" />
            {{ $t('global.import') }}
          </button>
          <button class="btn-primary h-full" @click="openAddBomModal">
            <div i="carbon-intent-request-create" />
            {{ $t('global.new') }}
          </button>
        </div>
      </div>
    </div>
    <div class="my-8">
      <CTable
        :columns="columns"
        :rows="state.boms"
        @row-click="goToBomDetails"
        @row-select="onRowselected"
        :is-loading="state.isLoading"
        :is-loading-more="state.isLoadingMore"
        :empty-message="t('boms.no_found')"
      >
        <template #image="{ item: bom }">
          <div
            class="col-span-1 flex items-center min-h-6 max-h-6 overflow-hidden rounded"
            @click.stop.prevent="openFullScreenImageModal(bom?.image)"
          >
            <img :src="bom.image" v-if="bom?.image" class="object-contain w-auto h-6 aspect-square" />
          </div>
        </template>

        <template #status="{ item: bom }">
          <div class="col-span-1 flex items-center gap-2">
            <PublishingStatusTag :status="bom.status" />
          </div>
        </template>
      </CTable>
      <div class="pt-6" id="issueObserver" />
    </div>

    <OModal :open="state.hasDeleteBomModalOpen">
      <template #content>
        <div class="flex flex-col gap-1">
          <h2 class="text-xl font-semibold">
            {{ $t('global.confirm_delete') }}
          </h2>
          <p>
            {{ $t('boms.delete_title') }}
          </p>
          <span
            class="mt-4 rounded-md bg-red-50 px-2 py-1 text-s font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
          >
            <b>Warning:&nbsp;</b>{{ $t('boms.delete_description') }}
          </span>
        </div>
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-secondary" :disabled="state.isDeleting" @click="closeDeleteBomModal">
            {{ t('global.cancel') }}
          </button>
          <button class="btn-danger bg-red-500 text-white" autofocus :disabled="state.isDeleting" @click="deleteBoms">
            {{ state.isDeleting ? t('global.deleting') : t('global.delete') }}
          </button>
        </div>
      </template>
    </OModal>

    <EntityCreationModal
      :open="state.hasAddBomModalOpen"
      default-type="Bom"
      :isBomPage="true"
      @close="closeAddBomModal"
      @create="createBom"
    />

    <GraphElementImportModal
      :open="state.isImportModalOpen"
      @close="closeImportModal"
      @import-success="loadPaginatedData"
      import-from="bom"
    />

    <!-- Image modal -->
    <OModal :open="state.isFullscreenImageModalOpen" @close="closeFullScreenImageModal">
      <template #content>
        <img :src="state.imagePath ?? undefined" />
      </template>

      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <button class="btn-primary w-full" @click="closeFullScreenImageModal">
            {{ $t('global.close') }}
          </button>
        </div>
      </template>
    </OModal>
  </div>
</template>
